<template>
<body class="menu-position-side menu-side-left full-screen with-content-panel">
    <div ui-view autoscroll="true" style="overflow: auto;height: 100%;" class="ng-scope">
        <div class="all-wrapper menu-side with-pattern ng-scope" style="padding: 0px !important;background: 0% 0% / 100% 100% rgb(9, 181, 133);display: flex;align-items: center;" ng-class="{'d-none' : emailSent}">
            <div class="auth-box-w" style="margin: 40px auto">
                <div class="logo-w" style="padding-bottom: 20px; padding-top: 30px">
                    <h3 class="h3">Cyruspay</h3>
                </div>
                <form name="login_form" v-on:submit.prevent="forgotPass()" action="" method="post" class="ng-pristine ng-invalid ng-invalid-required">
                    <h4>Forgot Password</h4>
                    <p>Please enter your email address and we'll send you an email to reset your password.</p>
                    <div class="form-group text-left">
                        <v-app>
                            <v-col class="format-input" cols="0" sm="12" md="12" style="padding-left: 0px">
                                <v-text-field autofocus v-model="edtEmail" label="Email" style="height: 40px" type="text" name="email" class="input-group--focused" required outlined></v-text-field>
                            </v-col>
                        </v-app>
                        <!-- <input v-model="edtEmail" class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" placeholder="Enter your email" type="text" name="email" ng-model="reset.email" required />
                        <div class="pre-icon os-icon os-icon-email-2-at2"></div> -->
                    </div>
                    <div class="row">
                        <div class="form-check-inline">
                            <router-link ui-sref="login" to="/login">Log in</router-link>
                        </div>
                        <div class="col-sm-9 text-right">
                            <button class="btn btn-primary" type="submit" ng-disabled="loading">
                                <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</body>
</template>

<script>
import * as External from '@/utils/External.js'
import * as Validate from "@/utils/Validate.js";
import * as Session from "@/utils/SessionStore"
import ApiManager from "@/api/ApiManager";
import {
    SEND_EMAIL_FORGOT_PASSWORD,
} from "@/store/actionsType";
export default {
    name: "ForgotPassword",
    mounted() {
        External.head_link("/assets/css/tt/styleRegister.css");
        External.head_link("/assets/css/tt/themeRegister.css");
        External.head_link("/assets/css/tt/mainLogin.css");
        External.head_link("/assets/css/tt/style(1)Login.css");
        External.head_link("/assets/css/tt/rocket.css");
    },
    destroyed() {
        External.del_link("/assets/css/tt/styleRegister.css");
        External.del_link("/assets/css/tt/themeRegister.css");
        External.del_link("/assets/css/tt/mainLogin.css");
        External.del_link("/assets/css/tt/style(1)Login.css");
        External.del_link("/assets/css/tt/rocket.css");
    },
    data() {
        return {
            edtEmail: "",
        };
    },
    methods: {
        forgotPass() {
            if (Validate.isEmail(this.edtEmail)) {
                var request = {
                    'callbackUrl': ApiManager.getUrlDomainUI() + "/resetpass",
                    'email': this.edtEmail
                }
                this.$root.$refs.AppCyrus.start()
                this.$store.dispatch(SEND_EMAIL_FORGOT_PASSWORD, request)
                .then (() => {
                    this.$root.$refs.AppCyrus.finish()
                    Session.set("email", this.edtEmail)
                    this.$router.push({
                        path: "/forgotpassdone"
                    });
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
            } else {
                alert("Email invalidate!")
            }
        },
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '/assets/css/company.css';
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.pagination {
    justify-content: center;
    text-align: center;
}
</style>
